<template>
  <div class="my_info" :class="{ isMobile }">
    <el-form
      :model="userInfo"
      label-width="120px"
      :label-position="isMobile ? 'top' : 'right'"
      size="small"
      label-suffix=" :"
    >
      <el-form-item label="用户名">
        <el-input
          v-model="saveUserInfoData.nickname"
          :placeholder="userInfo.nickname"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="密码">
        <el-input
          v-model="saveUserInfo.password"
          :placeholder="userInfo.password"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="手机">
        <!-- <el-input
          v-model="saveUserInfo.phone"
          :placeholder="userInfo.phone"
        ></el-input> -->
        <span>{{ userInfo.mobile }}</span>
      </el-form-item>
      <el-form-item label="vip等级">
        <span>vip{{ userInfo.vip_level }}</span>
      </el-form-item>
      <el-form-item label="vip到期时间">
        <span>{{ userInfo.vipEndTime }}</span>
      </el-form-item>
      <el-form-item label="我的分享链接" class="share_link">
        <div class="link">
          <span>{{ userInfo.shareLink }}</span
          ><span class="copy" @click="clickCopy">复制</span>
        </div>
      </el-form-item>
      <el-form-item label="我的二维码">
        <div class="qrcode" ref="qrCodeUrl"></div>
      </el-form-item>
      <el-form-item class="operate" label-width="0px">
        <div>
          <el-button @click="cancel" size="big">取消</el-button>
          <el-button @click="saveUserInfo" type="primary" size="big"
            >保存</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useClipboard from 'vue-clipboard3'
import { ElMessage } from 'element-plus'
import QRCode from 'qrcodejs2'
import { setUserInfo } from '@/api/user'

export default {
  name: 'myInfo',

  components: {},

  data() {
    return {
      userInfo: {},
      saveUserInfoData: {
        nickname: '',
        password: '',
        phone: '',
        shareLink: ''
      }
    }
  },

  mounted() {
    const userInfo = this.$store.state.userInfo || {}
    userInfo.vipEndTime = dayjs
      .unix(userInfo.vip_expired_time)
      .format('YYYY-MM-DD HH:mm:ss')
    userInfo.shareLink =
      'http://www.jukequant.com/register?recommended_id=' +
      userInfo.recommended_id
    this.userInfo = userInfo
    this.creatQrCode()
  },

  methods: {
    async clickCopy() {
      const { toClipboard } = useClipboard()
      try {
        //复制
        await toClipboard(this.userInfo.shareLink)
        ElMessage.success('复制成功')
      } catch (e) {
        //复制失败
        ElMessage.error('复制失败')
      }
    },

    creatQrCode() {
      const qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.userInfo.shareLink, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },

    saveUserInfo() {
      setUserInfo({
        nickname: this.saveUserInfoData.nickname
      }).then((res) => {
        if (res.data) {
          ElMessage({
            type: 'success',
            message: '修改成功'
          })
          this.showEdit = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my_info {
  position: relative;

  padding: 10px 20px;
  overflow: auto;

  .el-form-item {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    text-align: left;

    .copy {
      color: #409eff;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .share_link {
    display: flex;
  }

  .operate {
    text-align: center;
    border-bottom: none;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
</style>
