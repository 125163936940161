import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      token: sessionStorage.getItem('token') || null,
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')) || {}
    }
  },

  getters: {
    getToken(state) {
      return state.token
    },

    getUserInfo(state) {
      return state.userInfo
    }
  },

  mutations: {
    saveToken(state, token) {
      state.token = token
    },

    saveUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  }
})

export default store
