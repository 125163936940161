<template>
  <div class="home">
    <navigation></navigation>
    <div class="main">
      <router-view />
    </div>
    <panel></panel>
  </div>
</template>

<script>
import Panel from '@/layout/components/Panel'
import Navigation from './components/Navigation.vue'

export default {
  components: {
    Panel,
    Navigation
  }
}
</script>

<style lang="scss" scoped>
.home {
  .main {
    min-height: 800px;
  }
}
</style>
