import { createWebHistory, createRouter } from 'vue-router'
import MyInfo from '@/views/my/components/myInfo'
import Index from '@/layout/index'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      redirect: '/home',
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/home/index')
        },
        {
          path: 'about',
          name: 'about',
          component: () => import('@/views/about/index')
        },
        {
          path: 'company',
          name: 'company',
          component: () => import('@/views/company/index')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/views/register/index')
        },
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/login/index')
        },
        {
          path: 'download',
          name: 'download',
          component: () => import('@/views/download/index')
        },
        {
          path: 'document',
          name: 'document',
          component: () => import('@/views/download/document/index')
        },
        {
          path: 'password',
          name: 'password',
          component: () => import('@/views/password/index')
        },
        {
          path: '/preview',
          name: 'Preview',
          component: () => import('@/views/help/Preview')
        },
        {
          path: '/help',
          name: 'help',
          component: () => import('@/views/help/index')
        },
        {
          path: '/my',
          name: 'my',
          redirect: '/myInfo',
          component: () => import('@/views/my/index'),
          children: [
            {
              path: '/myInfo',
              name: 'myInfo',
              component: MyInfo
            },
            {
              path: '/bindWx',
              name: 'bindWx',
              component: () => import('@/views/my/components/bindWx')
            },
            {
              path: '/recommended',
              name: 'recommended',
              component: () => import('@/views/my/components/recommended')
            }
          ]
        }
      ]
    },
    {
      path: '/wxlogin',
      name: 'wxlogin',
      component: () => import('@/views/wxlogin/index')
    },
    {
      path: '/empty',
      name: 'empty',
      component: () => import('@/views/empty/index')
    }
  ]
})

export default router
