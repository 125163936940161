<template>
  <div class="app" :style="style">
    <router-view
      class="router-view"
      v-slot="{ Component }"
      v-if="isRouterAlive"
    >
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App',

  provide() {
    return {
      reload: this.reload
    }
  },

  data() {
    return {
      style: {},
      isRouterAlive: true
    }
  },

  mounted() {
    this.isMobile = 'ontouchstart' in document.documentElement
    if (!this.isMobile) {
      this.style.minWidth = '1200px'
    }
  },

  methods: {
    reload() {
      this.isRouterAlive = false
      this.$router.replace({ path: '/' })
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
}

::-webkit-scrollbar-track-piece {
  background-color: #ffff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: rgba(144, 147, 153, 0.5);
  border-radius: 15px;
  -webkit-border-radius: 15px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 7px;
  background-color: rgba(144, 147, 153, 0.5);
  border-radius: 15px;
  -webkit-border-radius: 15px;
}
</style>
