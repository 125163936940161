<template>
  <div class="panel" :class="{ isMobile }">
    <el-row style="">
      <el-col :span="6" class="nav">
        <div>快捷导航</div>
        <p>
          <el-link
            :style="{
              fontSize: isMobile ? '12px' : '16px'
            }"
            type="info"
            href="/#/download"
            target="_blank"
            >量化软件下载</el-link
          >
        </p>
        <p>
          <el-link
            :style="{
              fontSize: isMobile ? '12px' : '16px'
            }"
            type="info"
            href="http://www.jukequant.com/agreement.html"
            target="_blank"
            >软件使用协议</el-link
          >
        </p>
      </el-col>
      <el-col :span="10" class="content">
        <div>联系我们</div>
        <p>四川赤壁量化科技有限公司</p>
        <p>地址：成都市高新区交子金融科技中心A座</p>
        <p>邮箱：wuchen5580@foxmail.com</p>
      </el-col>
      <el-col :span="6" class="public-qr">
        <div></div>
        <p>微信公众号</p>
      </el-col>
    </el-row>
    <div class="bar">
      聚客量化 JukeQuant<a style="color:white; text-decoration: none;" href="https://beian.miit.gov.cn" target="_blank" > 蜀ICP备2021033033号</a> <a style="color:white; text-decoration: none;" href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank" >川公网安备51015602000378号</a> ©2019-2023 All Rights Reserved
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  data() {
    return {
      isMobile: 'ontouchstart' in document.documentElement
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  text-align: left;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  padding-top: 20px;
  background-color: #2c3e50;

  p {
    color: #909399;
  }

  .nav {
    padding-left: 100px;
  }

  .bar {
    padding: 11px;
    border-top: 1px solid rgba(216, 216, 216, 0.16);
    height: max-content;
    line-height: 17px;
    text-align: center;
  }

  .el-row {
    margin: 0 auto;
  }

  .public-qr {
    p {
      text-align: center;
    }

    div {
      width: 150px;
      height: 150px;
      margin: 0 auto;
      background-image: url('http://www.jukequant.com/static/img/public-qr.png');
      background-size: 100% 100%;
    }
  }

  .enterprise-qr {
    p {
      text-align: center;
    }

    div {
      width: 150px;
      height: 150px;
      margin: 0 auto;
      background-image: url('http://www.jukequant.com/static/img/enterprise-qr.png');
      background-size: 100% 100%;
    }
  }
}

.panel.isMobile {
  .nav {
    padding-left: 10px;

    div {
      font-size: 12px;
    }

    p {
      font-size: 10px;
    }
  }

  .public-qr {
    div {
      width: 50px;
      height: 50px;
    }
  }

  .enterprise-qr {
    div {
      width: 50px;
      height: 50px;
    }
  }

  .content {
    margin-bottom: 20px;

    div {
      font-size: 12px;
    }

    p {
      font-size: 10px;
      line-height: 14px;
      margin: 4px 0 0;
    }
  }

  .el-link--inner {
    font-size: 10px;
  }
}
</style>
