import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import 'flexible'

import App from '@/App.vue'
import router from '@/router'
import store from './store'

const app = createApp(App)
app.config.globalProperties.isMobile =
  'ontouchstart' in document.documentElement

app.use(router)
app.use(ElementPlus)
app.use(store)

app.mount('#app')
