<template>
  <div style="height: 60px"></div>
  <el-row v-if="!isMobile" class="navigation" :style="style">
    <el-col :span="6">
      <div class="name">
        <span class="cn">聚客量化</span>
        <span class="en">JuKeQuant</span>
      </div>
    </el-col>
    <el-col :span="12" class="tab_bar">
      <el-menu
        router
        mode="horizontal"
        active-text-color="#eeeeee"
        background-color="#2c3e50"
        text-color="#eeeeee"
        :ellipsis="false"
        style="--el-menu-border-color: #2c3e50"
        @select="selectSubMenu"
      >
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/company">聚客量化</el-menu-item>
        <el-menu-item index="/download">产品中心</el-menu-item>
        <el-menu-item index="/help">信息公开</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item v-if="token" index="/kline">期货行情</el-menu-item>
        <el-menu-item v-if="token" index="/kline2">股票行情</el-menu-item>
	<!--el-menu-item v-if="token" index="/test">期货行情2</el-menu-item-->
      </el-menu>
    </el-col>
    <el-col :span="6" class="user_status">
      <el-space wrap spacer="|" style="color: #eeeeee; padding: 10px 0">
        <div v-if="!token">
          <el-button
            class="login"
            type="primary"
            size="small"
            @click="$router.push('/login')"
            >登录</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="$router.push('/register')"
            >注册</el-button
          >
        </div>
        <div v-else>
          <span style="margin-right: 20px">{{ userInfo.nickname }}</span>
          <el-tooltip
            style="color: #e2a74d; background-color: inherit; border: none"
            :content="getVipTime"
            placement="bottom"
            effect="light"
          >
            <el-button>期限</el-button>
          </el-tooltip>
        </div>
        <el-dropdown v-if="token">
          <span class="el-dropdown-link">
            <span>账户相关</span>
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <router-link style="text-decoration: none" to="/my">
                <el-dropdown-item> 个人中心 </el-dropdown-item>
              </router-link>
              <router-link style="text-decoration: none" to="/password">
                <el-dropdown-item>
                  {{ token ? '修改密码' : '密码找回' }}
                </el-dropdown-item>
              </router-link>
              <el-dropdown-item @click="exit"> 退出登录 </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-space>
    </el-col>
  </el-row>
  <div v-else class="mobile">
    <div class="left">
      <el-icon color="#fff" @click="hndleShowMenu"><expand /></el-icon>
    </div>
    <div class="center">
      <div class="name">
        <span class="cn">聚客量化</span>
        <span class="en">JuKeQuant</span>
      </div>
    </div>
    <div class="right">
      <div v-if="!token">
        <el-button
          class="login"
          type="primary"
          size="small"
          @click="$router.push('/login')"
          >登录</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="$router.push('/register')"
          >注册</el-button
        >
      </div>
      <div v-else>
        <span style="margin-right: 10px">{{ userInfo.nickname }}</span>
        <el-popover
          placement="bottom"
          :width="200"
          trigger="click"
          :content="getVipTime"
        >
          <template #reference>
            <el-button
              size="small"
              style="color: #e2a74d; background-color: inherit; border: none"
              >vip</el-button
            >
          </template>
        </el-popover>
      </div>
    </div>
    <el-drawer
      lock-scroll
      append-to-body
      :with-header="false"
      size="300"
      custom-class="phone-drawer"
      v-model="isShowMenu"
      direction="ltr"
    >
      <el-menu
        router
        active-text-color="#eeeeee"
        background-color="#fff"
        text-color="#666"
        style="
          --el-menu-border-color: #2c3e50;
          margin-top: 60px;
          width: 100px;
          height: 100%;
          border: none;
        "
        @select="selectSubMenu"
      >
        <!-- <el-menu-item></el-menu-item> -->
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/company">聚客量化</el-menu-item>
        <el-menu-item index="/download">产品中心</el-menu-item>
        <el-menu-item index="/help">信息公开</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item v-if="token" index="/kline">期货行情</el-menu-item>
        <el-menu-item v-if="token" index="/kline2">股票行情</el-menu-item>
	<!--el-menu-item v-if="token" index="/test">期货行情2</el-menu-item-->     
 </el-menu>
    </el-drawer>
  </div>
</template>

<script>
import { ArrowDown, Expand } from '@element-plus/icons-vue'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { removeToken } from '@/utils/auth'
import { getInfo } from '@/api/user'

export default {
  name: 'Navigation',

  components: {
    ArrowDown,
    Expand
  },

  inject: ['reload'],

  data() {
    return {
      token: this.$store.state.token,
      userInfo: this.$store.state.userInfo,
      isShowNav: true,
      path: this.$router.currentRoute,
      style: {},
      isMobile: false,
      isShowMenu: false
    }
  },

  mounted() {
    this.isMobile = 'ontouchstart' in document.documentElement
    if (!this.isMobile) {
      this.style.minWidth = '1200px'
    }
  },

  watch: {
    path: {
      immediate: true,
      handler: function (val) {
        if (val.path !== '/login' && val.path !== '/register') {
          this.isShowNav = true
        } else {
          this.isShowNav = false
        }
      }
    },

    getToken(val) {
      this.token = val
    },

    getUserInfo(val) {
      this.userInfo = val
    }
  },

  computed: {
    ...mapGetters(['getToken', 'getUserInfo']),

    getVipTime() {
      const time = dayjs.unix(this.userInfo.vip_expired_time)

      if (time.valueOf() > dayjs().valueOf()) {
        return (
          '期时间为 ' +
          time.year() +
          '-' +
          (time.month() + 1) +
          '-' +
          time.date() +
          ' ' +
          time.hour() +
          ':' +
          time.minute() +
          ':' +
          time.second()
        )
      } else {
        return 'vip已过期'
      }
    }
  },

  methods: {
    hndleShowMenu() {
      this.isShowMenu = true
    },

    clickKLine() {},

    // vipTip() {
    //   ElMessage({
    //     showClose: true,
    //     message: this.getVipTime,
    //     type: 'warning'
    //   })
    // },

    selectSubMenu(index) {
      if (index == '/kline') {
          //'http://market.jukequant.com:880/#/main?token=' + this.userInfo.token
  //      window.open(
 //         'http://market.jukequant.com:880/quote?token=' + this.userInfo.token
//        )

window.open('https://futures.jukequant.com/?token=' + this.userInfo.token);

        // window.open('http://127.0.0.1:8080/#/main?token=' + this.userInfo.token)
        this.$router.go(0)
      }
      if (index == '/kline2') {
        //window.open('http://market.jukequant.com:8800/quote?token=' + this.userInfo.token)
        window.open('https://z.hx168.com.cn/h5/juke/#/quote?token=' + this.userInfo.token)
        //window.open('http://49.234.16.50:8000/quote?token=' + this.userInfo.token)
        this.$router.go(0)
      }

// if (index == '/test') {
//        window.open('https://test.jukequant.com/?token=' + this.userInfo.token)
//        this.$router.go(0)
//      }
     
this.isShowMenu = false
    },

    exit() {
      sessionStorage.clear('userInfo')
      sessionStorage.clear('token')
      removeToken()
      this.token = null
      // this.reload()
      this.$router.go(0)
      // this.$router.replace({ path: '/empty' })
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  text-align: center;
  background-color: #2c3e50;
  position: fixed;
  z-index: 99;
  height: 62px;
  width: 100%;
  top: 0;

  .name {
    font-weight: 700;
    line-height: 60px;

    .cn {
      color: #ffffff;
    }

    .en {
      color: #2483ff;
      margin-left: 10px;
    }
  }

  .el-dropdown-link {
    color: #eeeeee;
  }
}

.login {
  margin-right: 10px;
}

.tab_bar {
  display: flex;
  justify-content: center;
}

.user_status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  color: #fff;
  height: 60px;
  background-color: #2c3e50;
  .left {
    font-size: 24px;
    padding: 10px;
  }

  .center {
    .name {
      font-weight: 700;
      line-height: 60px;

      .cn {
        color: #ffffff;
      }

      .en {
        color: #2483ff;
        margin-left: 10px;
      }
    }
  }

  .right {
    margin-right: 10px;
    .login {
      margin-right: 0;
    }
  }
}
</style>
